* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins";
}
.left_panel_container {
  position: fixed;
  background-color: white;
  border-right: solid rgb(240, 240, 240) 2px;
  height: 100vh;
}
.left_panel_logo {
  height: 40px;
}
.left_panel_profile {
  width: 250px;
}
.left_panel_profile_header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: solid rgb(220, 220, 220) 1px;

  padding-left: 8px;
}
.left_panel_profile_image {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 100%;
  margin-top: 25px;
  object-position: top top;
}
.left_panel_profile_name {
  font-size: 12px;
  font-weight: 900;
}
.left_panel_profile_address {
  font-size: 10px;
  font-weight: bold;
  color: rgb(133, 133, 133);
}
.left_panel_profile_counts {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: 12;
}
.left_panel_profile_count {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.left_panel_profile_count_number {
  font-size: 12px;
  font-weight: bolder;
}
.left_panel_profile_count_type {
  font-size: 10px;
  color: rgb(133, 133, 133);
  font-weight: bold;
}
.left_panel_screen_type {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
.left_panel_screen_type .isactivebar {
  height: 100%;
  width: 6px;
  background-color: rgb(254, 67, 110);
  border-radius: 0px 10px 10px 0px;
}
.left_panel_screen_type .isunactivebar {
  display: none;
}
.left_panel_screen_type .isactivecolor {
  color: rgb(254, 67, 110);
}

.left_panel_screen_type_mobile {
  height: 40px;
  display: flex;
  align-items: center;
}
.left_panel_screen_type_mobile .isactivebar {
  background-color: rgb(254, 67, 110);
  border-radius: 10px 10px 0px 0px;
}

.left_panel_screen_type_mobile .isunactivebar {
  display: none;
}

.left_panel_screen_type_mobile .isactivecolor {
  color: rgb(254, 67, 110);
}
