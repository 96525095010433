::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255);
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(186, 186, 186);
  border-radius: 10px;
}

@media (min-width: 1130px) {
  .lg_width {
    width: 1500px;
  }
}
